import { DebtPaymentStatus, Plan } from '@/types';
import { PlanScheduleItem } from '@/types';
import { getCanCreateNextDebtUpdate } from '../../../utils/getCanCreateNextDebtUpdate';
import { getDebtUpdateById } from './getDebtUpdateById';

export function getDebtPaymentStatus(
  plan: Plan,
  item: PlanScheduleItem
): DebtPaymentStatus | null {
  const debtUpdate = getDebtUpdateById(plan, item.debtUpdateId);
  const isCompleted = debtUpdate && debtUpdate.payment !== null;
  const { canCreateNextDebtUpdate } = getCanCreateNextDebtUpdate(plan, item);

  if (item.debtUpdateId && isCompleted) {
    let isPaymentBelowMinimum = false;
    if (item.minimumPayment !== null && item.totalPayment !== null) {
      isPaymentBelowMinimum = item.minimumPayment > item.totalPayment;
    }
    return isPaymentBelowMinimum
      ? DebtPaymentStatus.PaidBelowMinimum
      : DebtPaymentStatus.Paid;
  } else if (item.debtUpdateId && !isCompleted) {
    return DebtPaymentStatus.Ready;
  } else if (canCreateNextDebtUpdate) {
    return DebtPaymentStatus.Ready;
  } else if (!item.debtUpdateId && !canCreateNextDebtUpdate) {
    return DebtPaymentStatus.TooEarly;
  }

  return null;
}
