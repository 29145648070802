import { Debt } from '@/types';
import { calculateDebtMinimumPayment } from '../../calculateDebtMinimumPayment';
import { calculateMonthlyInterest } from '../../calculateMonthlyInterest';
import { getEffectiveApr } from '../../getEffectiveApr';

export function getMonthlyMinimumPayments(
  date: Date,
  activeDebts: Debt[],
  debtBalances: Map<string, number>,
  isActive: boolean
) {
  return activeDebts.reduce((sum, debt) => {
    const balance = debtBalances.get(debt.id) || 0;
    const monthlyInterest = calculateMonthlyInterest(
      balance,
      getEffectiveApr(debt, date)
    );
    const minimumPayment = calculateDebtMinimumPayment(
      debt,
      balance,
      monthlyInterest,
      debt.debtUpdate
    );
    if (
      debt.debtUpdate?.minimumPayment !== null &&
      debt.debtUpdate?.minimumPayment !== undefined
    ) {
      if (isActive) {
        return (
          sum +
          Math.min(balance + monthlyInterest, debt.debtUpdate.minimumPayment)
        );
      } else {
        return sum + Math.max(minimumPayment, debt.debtUpdate.minimumPayment);
      }
    }
    return sum + Math.min(balance, minimumPayment);
  }, 0);
}
