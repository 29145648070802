export function getInterestCharged(
  balance?: number | null,
  apr?: number | null
): number | null {
  if (
    balance === null ||
    balance === undefined ||
    apr === null ||
    apr === undefined
  ) {
    return null;
  }
  return (balance * (Number(apr) / 100)) / 12;
}
